<template>
  <div class="col-12 mx-auto">
    <div
      v-if="dataSendCode && !modalErrorPagos"
      class="container-summary-quota p-3"
    >
      <h3 class="font-weight-bold mb-4 ps-md-5">Estado de cuenta</h3>
      <div
        class="d-flex flex-wrap col-md-11 px-0 pt-4 px-3 mx-auto"
        style="border-radius: 10px; background-color: #fafafa"
      >
        <div class="col-12 col-lg-9 pe-lg-1 mb-3">
          <p class="mb-1">{{ dataSendCode.nombres }}</p>
          <p class="mb-1">{{ dataSendCode.nit }}</p>
          <p class="mb-1">Cel: {{ dataSendCode.celular }}</p>
          <p class="mb-1">{{ dataSendCode.email }}</p>
        </div>
        <div class="col-12 col-lg-3 ps-lg-2 mb-3">
          <p class="mb-1">
            Fecha de creación: {{ dataSendCode.fecha_creacion }}
          </p>
          <p>IP: {{ dataSendCode.ip }}</p>
        </div>
      </div>
      <div class="d-flex flex-wrap px-md-4 pt-4 pb-0">
        <div class="d-flex flex-wrap justify-content-center w-100">
          <div class="container-resumen mb-4">
            <p class="mb-1"><b>Disponible</b></p>
            <span class="price-summary-quota"
              >${{
                new Intl.NumberFormat('de-DE').format(dataSendCode.disponible)
              }}</span
            >
            <div class="container-progress">
              <div
                class="progress-quota"
                :style="
                  'width: ' +
                    (dataSendCode.disponible * 100) / dataSendCode.cupo +
                    '%;'
                "
              ></div>
            </div>
            <p class="mb-4">
              Cupo total: ${{
                new Intl.NumberFormat('de-DE').format(dataSendCode.cupo)
              }}
            </p>
            <p class="mb-1"><b>Fecha de aprobación</b></p>
            <p class="mb-0">{{ dataSendCode.fechaAprobacion }}</p>
          </div>
          <div class="container-resumen mb-4">
            <div class="d-flex justify-content-between">
              <div>
                <p class="mb-1"><b>Pago mínimo</b></p>
                <p class="price-summary-quota mb-0">
                  ${{
                    new Intl.NumberFormat('de-DE').format(
                      dataSendCode.pagoMinimo,
                    )
                  }}
                </p>
              </div>
              <div class="d-none d-md-flex">
                <button class="bnt-primary my-auto">
                  <a href="https://portalpagos.cloverbolsos.co">
                    Pagar cuota
                  </a>
                </button>
              </div>
            </div>
            <p class="mb-1 mt-4"><b>Pago total</b></p>
            <p class="mb-0 d-flex">
              <span class="price-summary-quota"
                >${{
                  new Intl.NumberFormat('de-DE').format(dataSendCode.deudaTotal)
                }}</span
              >
              <!-- <span class="info-btn">?</span>-->
              <span class="ver-detalle-cuota" @click="modalCartera = true"
                >Ver detalle</span
              >
            </p>
            <div class="d-flex d-md-none mt-4">
              <button class="bnt-primary my-auto">
                <a href="https://portalpagos.cloverbolsos.co">
                  Pagar cuota
                </a>
              </button>
            </div>
          </div>
          <div class="container-resumen mb-4">
            <p class="mb-2"><b>Últimos pagos</b></p>
            <p v-if="dataSendCode.ultPagos.length == 0">No registra pagos</p>
            <p
              v-for="(pago, index) in dataSendCode.ultPagos"
              v-else
              :key="index + 'ult-pagos'"
              class="mb-0"
            >
              <span
                >{{ pago.fecha }} - ${{
                  new Intl.NumberFormat('de-DE').format(pago.valor)
                }}</span
              >
              <br />
            </p>
          </div>
          <div class="container-resumen mb-4">
            <p class="mb-1"><b>Fecha límite de pago</b></p>
            <div v-if="dataSendCode.extractoResumido.length > 0">
              <span class="price-summary-quota"
                >{{ dataSendCode.proximoPago }} - ${{
                  new Intl.NumberFormat('de-DE').format(
                    dataSendCode.cuotaCancelar,
                  )
                }}</span
              >
              <button
                class="mt-4 btn-plan-pagos-quota"
                @click="modalPagos = true"
              >
                Ver plan de pagos
              </button>
            </div>
            <p v-else>No programado</p>
          </div>
        </div>
        <div class="container-last-shopping">
          <p class="w-100"><b>Últimas compras</b></p>
          <div
            v-if="dataSendCode.ultCompras.length > 0"
            class="d-flex flex-wrap w-100"
          >
            <div
              v-for="(compra, index) in dataSendCode.ultCompras"
              :key="index + 'ilt-compras'"
              class="container-shopping"
            >
              <p class="mb-1">
                <b>{{ compra.fecha }}</b>
              </p>
              <p>
                {{ compra.bodega_nombre }} <br />
                ${{ new Intl.NumberFormat('de-DE').format(compra.valor) }}
              </p>
            </div>
          </div>
          <p v-else>No registra compras realizadas</p>
        </div>
      </div>
    </div>
    <b-modal
      v-if="!modalErrorPagos && dataSendCode"
      id="plan-de-pagos"
      v-model="modalPagos"
      size="lg"
      centered
      hide-header
      hide-footer
    >
      <div class="d-flex w-100">
        <svg
          id="Shopicons_Light_Close"
          style="cursor: pointer"
          class="ms-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="16.325"
          height="16.325"
          viewBox="0 0 16.325 16.325"
          @click="modalPagos = false"
        >
          <path
            id="Trazado_4945"
            data-name="Trazado 4945"
            d="M0,0H16.325V16.325H0Z"
            fill="none"
          />
          <g id="Shopicon" transform="translate(2.24 2.24)">
            <path
              id="Trazado_4946"
              data-name="Trazado 4946"
              d="M7.067,18.431l5.442-5.442,5.442,5.442.481-.481-5.442-5.442,5.442-5.442-.481-.481-5.442,5.442L7.067,6.586l-.481.481,5.442,5.442L6.586,17.95Z"
              transform="translate(-6.586 -6.586)"
            />
          </g>
        </svg>
      </div>
      <div class="">
        <h3 class="text-center mb-4">Pagos pendientes</h3>
        <table class="table table-striped table-hover">
          <tr style="border-bottom: 1px solid gray">
            <th class="text-center py-2">
              <b style="font-size: 1rem">Vencimiento</b>
            </th>
            <th class="text-center py-2">
              <b style="font-size: 1rem">Valor a pagar</b>
            </th>
          </tr>
          <tr
            v-for="(pago, index) in dataSendCode.extractoResumido"
            :key="index + 'pagos-pendientes'"
            style="border-bottom: 1px solid gray"
            :style="index % 2 == 0 ? 'background-color: #FAFAFA' : ''"
          >
            <td class="text-center py-2">{{ pago.vencimiento }}</td>
            <td class="text-center py-2">
              <b>${{ new Intl.NumberFormat('de-DE').format(pago.saldo) }}</b>
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
    <b-modal
      v-if="!modalErrorPagos && dataSendCode"
      id="detalle-de-cartera"
      v-model="modalCartera"
      centered
      hide-header
      hide-footer
    >
      <div class="d-flex w-100">
        <svg
          id="Shopicons_Light_Close"
          style="cursor: pointer"
          class="ms-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="16.325"
          height="16.325"
          viewBox="0 0 16.325 16.325"
          @click="modalCartera = false"
        >
          <path
            id="Trazado_4945"
            data-name="Trazado 4945"
            d="M0,0H16.325V16.325H0Z"
            fill="none"
          />
          <g id="Shopicon" transform="translate(2.24 2.24)">
            <path
              id="Trazado_4946"
              data-name="Trazado 4946"
              d="M7.067,18.431l5.442-5.442,5.442,5.442.481-.481-5.442-5.442,5.442-5.442-.481-.481-5.442,5.442L7.067,6.586l-.481.481,5.442,5.442L6.586,17.95Z"
              transform="translate(-6.586 -6.586)"
            />
          </g>
        </svg>
      </div>
      <h3 class="mb-3 pt-4 text-center">Detalle Cartera</h3>
      <div class="p-4">
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start">Saldo vencido</p>
          <p class="col-6 text-end pe-2">
            ${{ new Intl.NumberFormat('de-DE').format(dataSendCode.vencido) }}
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start mb-0">Saldo sin vencer</p>
          <p class="col-6 mb-0 text-end pe-2">
            ${{ new Intl.NumberFormat('de-DE').format(dataSendCode.sinVencer) }}
          </p>
          <hr class="col-5 ms-auto mt-2 me-0" />
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start"><b>Total capital</b></p>
          <p class="col-6 text-end pe-2">
            ${{
              new Intl.NumberFormat('de-DE').format(dataSendCode.totalCartera)
            }}
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start">Saldo Intereses Mora</p>
          <p class="col-6 text-end pe-2">
            ${{ new Intl.NumberFormat('de-DE').format(dataSendCode.intereses) }}
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start">Gastos cobranza</p>
          <p class="col-6 text-end pe-2">
            ${{
              new Intl.NumberFormat('de-DE').format(dataSendCode.gastosCobro)
            }}
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start">Honorarios</p>
          <p class="col-6 text-end pe-2">
            ${{
              new Intl.NumberFormat('de-DE').format(dataSendCode.honorarios)
            }}
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p v-if="dataSendCode.papeleria > 0" class="col-6 text-start mb-0">
            Papelería
          </p>
          <p v-if="dataSendCode.papeleria > 0" class="col-6 mb-0 text-end pe-2">
            ${{ new Intl.NumberFormat('de-DE').format(dataSendCode.papeleria) }}
          </p>
          <hr class="col-5 ms-auto mt-2 me-0" />
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start"><b>Deuda total</b></p>
          <p class="col-6 text-end pe-2">
            <b
              >${{
                new Intl.NumberFormat('de-DE').format(dataSendCode.deudaTotal)
              }}</b
            >
          </p>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="error-plan-de-pagos"
      v-model="modalErrorPagos"
      no-close-on-backdrop
      centered
      hide-header
      hide-footer
    >
      <div class="p-4">
        <h2 class="mb-4 text-center"><b>Parece que algo salió mal</b></h2>
        <p class="text-center">{{ messageError }}</p>
        <button class="w-100 bnt-primary" @click="backInfoCupo()">
          Entendido
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      modalPagos: false,
      modalCartera: false,
      payment: false,
      actualStep: 1,
      values: '',
      priceInput: null,

      selectPago: null,
      validatePrice: false,
      errValor: '',
      price: null,
      htmlPayU: '',
      dataSendCode: null,
      modalErrorPagos: false,
      messageError: '',
    }
  },
  watch: {
    values() {
      if (this.values == 'PM') {
        this.price = this.dataSendCode.pagoMinimo
      } else if (this.values == 'PT') {
        this.price = this.dataSendCode.deudaTotal
      } else if (this.values == 'OV') {
        this.price = this.priceInput
      }
    },
    priceInput() {
      if (this.values == 'OV') {
        this.price = this.priceInput
      }
    },
    actualStep() {
      ;(this.validatePrice = true), window.scrollTo(0, 0)
    },
    payment() {
      window.scrollTo(0, 0)
    },
  },
  beforeCreate() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover-simple'
    this.$store.state.layouts.menuLayoutClover = 'menu-layout-simple'
  },
  beforeDestroy() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover'
    this.$store.state.layouts.menuLayoutClover = 'menu-clover'
  },
  async mounted() {
    this.$store.state.loader = true
    let data = new FormData()
    data.append('token', this.$route.params.token)
    await this.axios({
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: 'cupopersonal/accountStatus/validateToken',
      data: data,
    })
      .then(response => {
        this.dataSendCode = response.data
      })
      .catch(err => {
        if (err.response.status == 422) {
          this.messageError = err.response.data.message
          this.modalErrorPagos = true
        } else {
          location.href = 'https://portalpagos.cloverbolsos.co'
        }
      })
  },
  methods: {
    backInfoCupo() {
      this.$store.state.layouts.footerLayoutClover = 'footer-clover'
      this.$store.state.layouts.menuLayoutClover = 'menu-clover'
      location.href = 'https://portalpagos.cloverbolsos.co'
    },
  },
}
</script>
